<template>
    <vs-popup class="memberAdd_popup" title="Catalogue" :active.sync="popupActive">
        <template v-if="chargement" >
            <h2 class="text-center center">Chargement ...</h2>
        </template>
        <template v-else >
            <vs-row>
                <vs-col vs-type="flex" vs-w="12" vs-xs="12" style="padding-right:10px;">
                    <div class="vx-col w-full">
                        <vs-input class="w-full" label="Nom produit" v-model="produit.produit" maxlength="30"/>
                    </div>
                </vs-col>
                <!-- <vs-col vs-type="flex" vs-w="6" vs-xs="12" style="padding-left:10px;">
                    <div class="vx-col w-full">
                       
                          <vs-select v-model="produit.categorie" class="w-full select-large" label="Catégorie" v-on:change="detectAddCat()" >
                            <span v-for="(cat, index) in cat_list" :key="index">
                                <vs-select-item :value="cat" :text="cat" />
                            </span>
                            <vs-select-item value="?" text="+ AJOUTER"/>
                        </vs-select>  
                    </div>
                </vs-col> -->
            </vs-row>
            
            <vs-divider/>
            Categorie
            
            <vs-row vs-align="flex-start" vs-type="flex" vs-justify="center" vs-w="12" class="mt-6">
                
                 <vs-col  v-for="(cat, index) in cat_list" :key="index" vs-w="3" vs-sm="6" v-on:change="detectAddCat()">
                    <vs-radio v-model="produit.categorie" vs-name="radios1" :vs-value="cat"> {{ cat }}</vs-radio>
                </vs-col>
                <vs-col vs-w="3"  vs-sm="6" >
                    <vs-button color="success" type="filled" @click="addCategorie"> + AJOUTER</vs-button>
                    <!-- <vs-radio v-model="produit.categorie" vs-name="radios1" vs-value="?" v-on:change="detectAddCat()"> + AJOUTER</vs-radio> -->
                </vs-col>

            </vs-row>
             <vs-divider/>
            Groupe
            <br>
            <vs-row vs-align="flex-start" vs-type="flex" vs-justify="center" vs-w="12" class="mt-6">
                 <vs-col  v-for="(item,index) in listGroup" :key="index" vs-w="3">
                    <vs-radio v-model="produit.machine" vs-name="radios2" :vs-value="item.machine"> {{ item.nom }}</vs-radio>
                </vs-col>
                <!-- <vs-select v-model="produit.machine" class="w-full select-large" label="Groupe" >
                      <vs-select-item :key="index" :value="item.machine" :text="item.nom" v-for="(item,index) in listGroup" class="w-full" />
                </vs-select> -->
            </vs-row>
            <vs-row>
                <vs-col vs-type="flex" vs-w="12" vs-xs="12" >
                    <vs-divider></vs-divider>
                </vs-col> 
            </vs-row>

            <vs-row>
                <vs-col vs-type="flex" vs-w="4" vs-xs="12" style="padding-right:10px;">
                    <div class="vx-col w-full">
                        <vs-input class="w-full" label=" HT" v-model="produit.ht" type="number" min="0" v-on:change="changeHT"/>
                    </div>
                </vs-col>
                <vs-col vs-type="flex" vs-w="4" vs-xs="12" style="padding-left:10px;">
                    <div class="vx-col w-full">
                        <vs-input class="w-full" label="TVA %" v-model="produit.tva" type="number" min="0" v-on:change="changeTVA"/>
                    </div>
                </vs-col>
                <vs-col vs-type="flex" vs-w="4" vs-xs="12" style="padding-left:10px;">
                    <div class="vx-col w-full">
                        <vs-input class="w-full" label="TTC" v-model="produit.ttc" type="number" min="0" v-on:change="changeTTC" />
                    </div>
                </vs-col>
            </vs-row>

            <vs-row>
                <vs-col vs-type="flex" vs-w="12" vs-xs="12" >
                    <br/>
                </vs-col> 
            </vs-row>

            <vs-row>
                <vs-col vs-type="flex" vs-w="6" vs-xs="12" style="padding-right:10px;">
                    <div class="vx-col w-full">
                        <vs-input class="w-full" label="Compte produit" v-model="produit.compte_produit" />
                    </div>
                </vs-col>
                <vs-col vs-type="flex" vs-w="6" vs-xs="12" style="padding-left:10px;">
                    <div class="vx-col w-full">
                        <vs-input class="w-full" label="Compte TVA" v-model="produit.compte_tva" />
                    </div>
                </vs-col>
            </vs-row>

            <vs-row>
                <vs-col vs-type="flex" vs-w="12" vs-xs="12" >
                    <vs-divider></vs-divider>
                </vs-col> 
            </vs-row>

            <vs-row>
                <vs-col vs-type="flex" vs-w="6" vs-xs="12" style="padding-right:10px;">
                    <div class="vx-col w-full">
                        <vs-input class="w-full" label="Crédit" v-model="produit.credit" type="number" />
                    </div>
                </vs-col>
                <vs-col vs-type="flex" vs-w="6" vs-xs="12" style="padding-left:10px;">
                    <div class="vx-col w-full">
                        <vs-input class="w-full" label="Jour expiration" v-model="produit.day" type="number" />
                    </div>
                </vs-col>
            </vs-row>

            <vs-row>
                <vs-col vs-type="flex" vs-w="12" vs-xs="12" >
                    <vs-divider></vs-divider>
                </vs-col> 
            </vs-row>

            
             <vs-row vs-align="flex-start" vs-type="flex" vs-justify="center" vs-w="12" class="mt-6">
                <vs-col  vs-w="4">
                    Autoriser la réduction 
                    </vs-col>
                <vs-col  vs-w="4">
                        <vs-radio v-model="produit.discount" vs-name="radios3" vs-value="1"> Oui </vs-radio>
                </vs-col>
                <vs-col  vs-w="4">
                    <vs-radio v-model="produit.discount" vs-name="radios3" vs-value="0"> Non </vs-radio>
                </vs-col>
                
            </vs-row>
            <vs-divider/>
             <vs-row vs-align="flex-start" vs-type="flex" vs-justify="center" vs-w="12" class="mt-6">
                <vs-col  vs-w="4">
                Affichage internet
                </vs-col>
                <vs-col  vs-w="4">
                    <vs-radio  v-model="produit.web" vs-name="radios4" vs-value="1"> Oui </vs-radio>
                </vs-col>
                <vs-col  vs-w="4">
                    <vs-radio v-model="produit.web" vs-name="radios4" vs-value="0"> Non </vs-radio>
                </vs-col>
            
             </vs-row>

            <vs-row>
                <vs-col vs-type="flex" vs-w="6" vs-xs="12" style="padding-right:10px;">
                    <div class="vx-col w-full">
                    </div>
                </vs-col>
                <vs-col vs-type="flex" vs-w="6" vs-xs="12" style="padding-left:10px;">
                    <div class="vx-col w-full">

                    </div>
                </vs-col>
            </vs-row>
            
            <vs-divider/>
            <vs-row>
                <vs-col vs-type="flex" vs-justify="space-between" vs-align="center" vs-xs="12">
                    <template v-if="!uid">
                        <div></div>
                    </template>
                    <template v-else>
                        <vs-button color="danger" type="filled" v-on:click="del()">Supprimer</vs-button>
                    </template>

                    <vs-button color="success" type="filled" v-on:click="add()">
                        <template v-if="!uid">
                            Ajouter
                        </template>
                        <template v-else>
                            Modifier
                        </template>
                    </vs-button>
                </vs-col>
            </vs-row>

        </template>
    </vs-popup>
</template>

<style lang="scss">
.memberAdd_popup
{

}
</style>

<script>

import Catalogue  from '@/database/models/catalogue';
import Machine  from '@/database/models/machine';
import autoRefresh from '@/assets/utils/autoRefresh'
// import moment from 'moment'
import vSelect from 'vue-select'


export default {
    components:{
        Machine,
        autoRefresh,
        'v-select': vSelect,
	},
    props: {
    },
    data(){
        return{
            popupActive:false,
            callback:null,
            chargement:false,

            cat_list:[],
            produit:{
                web:0,
                categorie:'',
                produit:'',
                credit:0,
                tva:20,
                ht:0,
                ttc:0,
                day:0,
                machine:undefined,
                discount:0,
                compte_produit:"",
                compte_tva:"",
                
            },
            uid: undefined,
            groupe:"",
            listGroup:[],
            listMachine:[],
        }
    },
    watch:{
        $route (){
            //console.log("YYEEAAHHH");
        },
    },
    beforeMount:function(){
        this.reset()
    },
    mounted:function(){
        autoRefresh.SetCallback(async()=>{
            let machineList= await  Machine.AwaitGetAllMemory();
            let aux =[];
            this.listMachine=machineList;
            
            for (let i in machineList){
               
                
                if( machineList[i].type=="g" && machineList[i].active==1 ){
                        
                        let aux1={
                            nom:machineList[i].name,
                            machine:machineList[i].uid,

                        }
                        aux.push(aux1)
                   
                    
                }
                
            }
            this.listGroup=aux


        })
        autoRefresh.refresh()

    },
    methods:{
        openPopup( data, callback )
        {
            this.reset()
            this.callback    = null
            this.chargement  = false
            this.popupActive = true
            this.uid        = undefined
        
            //si envoi de data
            if(data)
            {
                if(data.uid)
                    this.uid = data.uid

                this.produit =  JSON.parse(JSON.stringify(data))
                if(this.produit.machine!=undefined){
                
                    let aux1={
                        nom:this.listMachine[this.produit.machine].name,
                        machine:this.listMachine[this.produit.machine].uid,

                    }
                    this.listGroup.push(aux1)
                }
            
                this.produit['uid'] = undefined
            }

            if(callback)
                this.callback = callback

            
        },
        
        add()
        {
            if(this.produit.produit.length<3)
                return alert('Nom produit trop court')
            if(this.produit.categorie.length==0)
                return alert('Veuillez choisir une catégorie')
            if(this.produit.categorie.length>30)
                return alert('Nom produit trop long !')

            this.chargement = true
            if(this.uid )
            {
                //Ajout produit
                Catalogue.update(this.uid, this.produit )
                .then(()=>{
                    
                    //callback event
                    if(this.callback)
                        this.callback(this.uid)
                    //Event refresh
                    this.$emit('refresh')
                    //fermeture popup
                    this.popupActive = false
                })
            }
            else
            {
                
                //Ajout produit
                Catalogue.add(this.produit)
                .then((uid)=>{
                    //callback event
                    if(this.callback)
                        this.callback(uid)
                    //Event refresh
                    this.$emit('refresh')
                    //fermeture popup
                    this.popupActive = false
                    
                })
            }
        },

        del()
        {
            //supprimer produit
           
            Catalogue.remove(this.uid)
            .then(()=>{
                //callback event
                if(this.callback)
                    this.callback(this.uid)
                //Event refresh
                this.$emit('refresh')
            })
            //fermeture popup
            this.popupActive = false
           

        },

        reset(){
            this.produit.web       = 0,
            this.produit.categorie = '',
            this.produit.produit   = '',
            this.produit.credit    = 0,
            this.produit.tva       = 20,
            this.produit.ht        = 0,
            this.produit.ttc       = 0,
            this.produit.day       = 0,
            this.produit.machine   = undefined,
            this.produit.discount  = 0
            this.produit.compte_tva = "";
            this.produit.compte_produit = "";

            this.uid = undefined
            this.chargement = false

            //build categorie
            Catalogue.getTabAllMemory((list_cat)=>
            {
                this.cat_list = []
                for( let i=0; i<list_cat.length; i++)
                if( this.cat_list.indexOf( list_cat[i].categorie) == -1 )
                    this.cat_list.push(list_cat[i].categorie)

            })
        },


        changeTVA(){
            this.produit.ttc = parseFloat((this.produit.ht*( 1+(this.produit.tva/100))).toFixed(2))
        },
        changeTTC(){
            this.produit.ht  = parseFloat((this.produit.ttc/( 1+(this.produit.tva/100))).toFixed(2))
        },
        changeHT(){
            this.produit.ttc = parseFloat((this.produit.ht*( 1+(this.produit.tva/100))).toFixed(2))
        },
        addCategorie(){
            this.produit.categorie ='?';
            this.detectAddCat();
        },

        detectAddCat(){
            //Si demande de catégorie
            if(this.produit.categorie =='?')
            {
                this.produit.categorie = window.prompt('Nom de la nouvelle catégorie ?');

                if(this.produit.categorie.length>25)
                    return alert('Nom catégorie trop longue !');

                this.cat_list.push( this.produit.categorie)
            }
        }
    }
}

</script>