<template>
    <vs-popup class="changeCat_popup" title="Change catégorie" :active.sync="popupActive">
        <vs-row>
                <div class="vx-row mb-6 w-full">
                    <div class="vx-col w-full">
                        <vs-select v-model="cat_select" class="w-full select-large" label="Catégorie" >
                            <span v-for="(cat, index) in cat_list" :key="index">
                                <vs-select-item :value="cat" :text="cat" />
                            </span>
                        </vs-select>
                        <br/>
                    </div>
                    <div class="vx-col w-full">
                        <vs-input class="w-full" label="Nouveau nom" v-model="newName" />
                    </div>
                </div>
        </vs-row>
        <vs-divider/>
        <vs-row>
            <vs-col vs-type="flex" vs-justify="space-between" vs-align="center" vs-xs="12">
                <div></div>
                <vs-button color="success" type="filled" v-on:click="change()">
                        Changer
                </vs-button>
            </vs-col>
        </vs-row>
    </vs-popup>
</template>

<style lang="scss">
.changeCat_popup
{

}
</style>

<script>

import Utils from '@/assets/utils/utils';
import Catalogue  from '@/database/models/catalogue';

export default {
    components:{
	},
    props: {
    },
    data(){
        return{
            popupActive:false,
            callback:null,
            cat_list:[],
            cat_select:'',
            newName:'',
        }
    },
    watch:{
        $route (){
        },
    },
    beforeMount:function(){

    },
    methods:{
        openPopup( callback )
        {
            this.callback    = null
            this.popupActive = true
            this.cat_list    = []
            this.cat_select  = ''
            this.newName     = ''

            if(callback)
                this.callback = callback

            //build categorie
            Catalogue.getTabAllMemory((list_cat)=>
            {
                this.cat_list = []
                for( let i=0; i<list_cat.length; i++)
                if( this.cat_list.indexOf( list_cat[i].categorie) == -1 )
                    this.cat_list.push( list_cat[i].categorie)

            })
        },

        change()
        {
            if( this.cat_select == '')
                return alert('Aucune catégorie choisie !')
            if( this.newName == '')
                return alert('Aucun nouveau nom saisie !')

            //changement
            Catalogue.getTabAllMemory((list_cat)=>
            {
                for( var i=0; i<list_cat.length; i++)
                if( list_cat[i].categorie )
                if( list_cat[i].categorie == this.cat_select )
                {
                    let obj = {
                        categorie: Utils.clone(this.newName),
                    };
                    if( list_cat[i].uid )
                        Catalogue.update( list_cat[i].uid, obj ).then(()=>{})
                }
                //callback event
                if(this.callback)
                    this.callback(this.uid)
                //Event refresh
                this.$emit('refresh')
            })
            
            this.popupActive = false
        }
    }
}

</script>